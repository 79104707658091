import {_urlNews, _urlNewsBlock, request} from "../api";
import {NEWS_CONST} from "../constants";

export const GetNews = ({
                            reset = true,
                            offset = 0,
                            category,
                            limit = 6,
                        } = {}) => {
    let url = `${_urlNews}?offset=${offset}&limit=${limit}`;
    category && (url += `&category=${category}`);
    const requestData = {
        url,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                console.log('news data', data)
                data && dispatch({
                    type: NEWS_CONST.GET_NEWS,
                    payload: {
                        data,
                        reset,
                        hasMore: data.length === limit,
                    }
                })
            })
    };
};

export const GetNewsBlock = () => {
    const requestData = {
        url:`${_urlNewsBlock}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: NEWS_CONST.GET_NEWS_BLOCK,
                    payload: data
                })
            })
    };
}