// import packages
import React, {useEffect, useRef, useState} from 'react';
import moment from "moment";
import {connect} from "react-redux";

//Import Components
import {Player, ControlBar, PlayToggle, LoadingSpinner} from 'video-react';
import {Modal} from "antd";
import Slider from "react-slick";

//Import assets
import "./NewsModal.scss"
import 'video-react/dist/video-react.css';
import {ModalCloseIcon, PlayBtnIcon} from "../../../../assets/images";

// Import utils
import {getPropsFromState} from "../../../../redux/mapStateToProps";
import {generateImageMediaUrl} from "../../../../utils/generateMediaUrl";
import {generateFileMediaUrl} from "../../../../utils/generateImageMediaUrl";


function NewsModal(props) {
    const {news, modalOpen, toggleModalOpen, staticTexts} = props
    const [imgList, setImgList] = useState([])
    const [videoPlayActive, setVideoPlayActive] = useState(false)
    let sliderRef = useRef(null);
    let playerRef = useRef(null);


    useEffect(() => {
        if (news) {
            const list = [
                news?.mediaMain?.path,
                ...(news?.medias?.map(item => item?.path) || [])
            ];
            setImgList(list);
        }
    }, [news]);

    useEffect(() => {
        if (playerRef.current) {
            playerRef.current?.subscribeToStateChange(handleStateChange)
        }
    }, [playerRef.current]);

    useEffect(() => {
        if (modalOpen) {
            if (playerRef.current) {
                try {
                    playerRef.current?.load();
                    setVideoPlayActive(false)
                } catch (e) {
                }
            }
        }
    }, [modalOpen]);

    function closeModal() {
        if (sliderRef.current) {
            sliderRef.current.slickGoTo(0);
        }
        toggleModalOpen();
    }

    function navigateTo(link) {
        window.open(link)
    }


    function togglePlayer() {
        playerRef.current.play();
        setVideoPlayActive(true)
    }

    function handleStateChange(state) {
        if (state?.ended) {
            setVideoPlayActive(false)
        }
    }

    const settings = {
        dots: true,
        fade: true,
        arrows: false,
        autoplay: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        ref: sliderRef,
    };
    // modalOpen&&  console.log(news);
    // modalOpen && console.log('videoPlayActive', videoPlayActive);
    return modalOpen && <Modal centered
                               title={''}
                               open={modalOpen}
                               closeIcon={<ModalCloseIcon/>}
                               className={'newsModal'}
                               zIndex={10001}
                               maskClosable={true}
                               onCancel={closeModal}>
        <div className={'news-modal-wrapper'}>

            {news?.mediaVideo ? <div className={'news-video-wrapper'}>
                <div className={`video-cover ${videoPlayActive ? 'hide-cover' : ''}`}>
                    {news?.mediaMain?.path && <img src={generateFileMediaUrl(news?.mediaMain?.path)}
                                                   className={'cover-image'} alt={"about"}/>}
                    <div className={'play-btn'} onClick={togglePlayer}>
                        <PlayBtnIcon/>
                    </div>
                </div>
                <div className={'video-wrapper'}>
                    <Player
                        ref={playerRef}
                        autoPlay={false}
                        // muted={true}
                        // fluid={false}
                        // width={"100%"}
                        // height={"100%"}
                        // aspectRatio={'2:1'}
                        // poster={generateFileMediaUrl(news?.mediaMain?.path)}
                        src={generateFileMediaUrl(news?.mediaVideo?.path)}
                        preload={'auto'}
                    >
                    </Player>
                </div>
            </div> : <Slider {...settings}>
                {imgList?.length && imgList.map((item, index) => {
                    return <div className={'news-block-img'} key={index}>
                        <img src={generateImageMediaUrl(item)}/>
                    </div>
                })}
            </Slider>}

            <div className={'news-block-info'}>
                <div className={'news-date'}>
                    {moment(news?.date).format('ll')}
                </div>
                {
                    news?.link && <a href={news?.link} target={'_blank'} className={'news-modal-link'}>
                        {staticTexts?.news_page_link}
                    </a>
                }
                <div className={'news-block-title'}>
                    {news?.title}
                </div>
                <div className={'news-block-description'} dangerouslySetInnerHTML={{__html: news?.description}}/>
                {
                    news?.presentationFile &&
                    <div onClick={() => navigateTo(generateFileMediaUrl(news?.presentationFile?.path))}
                         className={'news-modal-pdf'}>
                        {staticTexts?.news_page_pdf}
                    </div>
                }
            </div>
        </div>
    </Modal>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'requestLoading',
    ])
};

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(NewsModal)