import {HOST_MEDIA_URL_V1} from '../redux/api'

export function generateImageMediaUrl(path) {
    if (path?.startsWith('http')) {
        return path;
    }
    return path ? `${HOST_MEDIA_URL_V1}/files${path}` : '';
}

export function generateFileMediaUrl(path) {
    if (path?.startsWith('http')){
        return path;
    }
    return path ? `${HOST_MEDIA_URL_V1}/resources${path}` : '';
}